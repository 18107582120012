import React, { useRef, useEffect } from 'react';
import JsBarcode from 'jsbarcode';
import { jsPDF } from 'jspdf';


const QRCodeComponent = ({ code, fData }) => {
  const barcodeRef = useRef(null);
  console.log('Preparing to send barcode data:', fData);

  useEffect(() => {
    if (code && barcodeRef.current) {
      // const barcodeValue = `*${code}*`; //wrapt the code with asterix to make the barcode scanable
      // Generate the barcode directly on the invisible SVG
      JsBarcode(barcodeRef.current, code, {
        format: 'CODE128',
        lineColor: '#000',
        width: 2,
        height: 40,
        displayValue: true,
        text: code,
      });

      // Delay to ensure barcode generation before attempting to save
      setTimeout(() => {
        saveBarcodeToStorage();
      }, 1000);
    }
  }, [code, fData.studentid]); // Re-run this effect if `code` or `studentId` changes

  const saveBarcodeToStorage = async () => {
    if (barcodeRef.current) {
      const svgElement = barcodeRef.current;
      let canvas = document.createElement('canvas');
      canvas.width = svgElement.clientWidth;
      canvas.height = svgElement.clientHeight;
      let ctx = canvas.getContext('2d');
      let data = new XMLSerializer().serializeToString(svgElement);
      let DOMURL = window.URL || window.webkitURL || window;
      let img = new Image();
      let svgBlob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'});
      let url = DOMURL.createObjectURL(svgBlob);

      img.onload = async () => {
        ctx.drawImage(img, 0, 0);
        DOMURL.revokeObjectURL(url);
        let barcodeDataURL = canvas.toDataURL('image/png');

        // Create a PDF and add the barcode image to it
        const pdf = new jsPDF();
        pdf.setFont("helvetica", "bold"); // Set font to bold
        pdf.setFontSize(16); // Set font size

        // Adding text to the PDF. Adjust the x and y coordinates as needed
        pdf.text("Tempest Photography", 10, 10);

        // After adding the text, add the barcode image below
        const imgProps = pdf.getImageProperties(barcodeDataURL);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        // Adjust the starting height of the barcode image to accommodate the text above it
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(barcodeDataURL, 'PNG', 50, 20, pdfWidth*0.40, pdfHeight*0.40); // Adjust Y offset to leave space for the text

        // Save the PDF
        pdf.save('Tempest-Photography-Registration-Barcode.pdf');

        // try {
        //   const response = await fetch('https://app-wiyal6pcqq-uc.a.run.app/api/save-barcode-to-storage', {
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({
        //       // barcodeDataURL: barcodeDataURL,
        //       // formData: fData,
        //       // docketID: code
        //       messageData: "It works"
        //     }),
        //   });

        //   const responseData = await response.json();
        //   console.log('Barcode saved:', responseData);

        // } catch (error) {
        //   console.error('Error saving barcode:', error);
        // }
      };
      img.src = url;
    }
  };

  return (
    <div className="flex justify-center mt-2">
      <div className="w-full max-w-lg bg-yellow-500 bg-opacity-60 p-5 rounded-lg">
        <p className="text-justify">Please make your way to the studios for your session. The following Docket ID has been sent to your Email. Make sure to present the same to the photographer when you come in for your session.</p>
        <br/>
        <p>{code ? 'Docket ID:' : 'Failed to generate barcode'}</p>
        {/* Invisible barcode SVG for JsBarcode to draw on; it's hidden and used only for saving */}
        <div className="flex justify-center">
        <svg ref={barcodeRef}></svg>
      </div>
      </div>
    </div>
  );
};

export default QRCodeComponent;
