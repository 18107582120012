import React, { useState,useRef, useEffect } from 'react'
import Topbar from './Components/Topbar'
import FormComponent from './Components/FormComponent'
import QRCodeComponent from './Components/QRCodeComponent'

function App () {
  const [code, setCode] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fullFormData, setFullFormData] = useState({});

  const resetForm = () => {
    setIsSubmitted(false);
    setCode('');
    formComponentReset();
    // Reset any other state variables related to form data if needed
  };

  useEffect(() => {
    console.log('fullFormData updated:', fullFormData);
    // Perform any action that depends on the updated state here
  }, [fullFormData]);

  const [formComponentReset, setFormComponentReset] = useState(() => () => {});

  const handleSubmit = async (formData) => {
    setLoading(true);

    console.log('fullFormData =', fullFormData)
    console.log('handleSubmit')

    // Prepare data to be sent to the backend
    const dataToSave = { ...formData }
    setFullFormData(dataToSave);

    try {
      const response = await fetch(`${ process.env.REACT_APP_API_URL }/api/save-form-data`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSave)
      });

      let responseData;
      try {
        responseData = await response.json();
      } catch (jsonError) {
        console.error('Failed to parse JSON response:', jsonError);
        throw new Error('Failed to parse JSON response');
      }

      if (response.ok) {
        setCode(responseData.docket);
        setIsSubmitted(true);
      } else {
        throw new Error(responseData.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to submit form: ' + error.message);
      setIsSubmitted(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App bg-fixed-background">
      <Topbar />
      {loading ? (
        // Show loading spinner without checking isSubmitted
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-20 w-20 border-b-2 border-blue-500"></div>
        </div>
      ) : isSubmitted ? (
        // Once loading is complete, and if isSubmitted is true, show the next state
        <>
          <div className="flex justify-center">
            <button onClick={resetForm} className="reset-button mb-2 mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Reset Form
            </button>
          </div>
          <QRCodeComponent code={code} fData={fullFormData} />
        </>
      ) : (
        // Default state showing the form
        <FormComponent onFormSubmit={handleSubmit} setResetFunction={setFormComponentReset} setLoading={setLoading} />
      )}
    </div>
  )
  }

export default App
