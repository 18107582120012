import React, { useState, useEffect } from 'react';
import { countries } from 'countries-list';
import { EnvelopeIcon } from '@heroicons/react/20/solid'
import AddressLookupDrop from './AddressLookupDrop';

function FormComponent({ onFormSubmit, setResetFunction, setLoading }) {
  const [formData, setFormData] = useState({
    studentid: '',
    university: { id: '', name: '' },  
    firstname: '',
    lastname: '',
    phoneExtension: '',
    phone: '',
    email: '',
    confirmEmail:'',
    marketingConsent: false,
    imageConsent: false,
    addressline1: '',
    addressline2: '',
    postcode: '',
    town: '',
    county: '',
    country: '', 
  });
  const [formErrors, setFormErrors] = useState({});
  const [lookupError, setLookupError] = useState(null);
  const [isUKSelected, setIsUKSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [universities, setUniversities] = useState([]);

  const defaultUniversities = [
    { id: 78, name: "University of Nottingham" },
    { id: 184, name: "Royal Holloway University of London" },
    { id: 224, name: "University of Exeter" },
  ];
  
  const isStudentIdEnabled = process.env.REACT_APP_STUDENT_ID_ENABLED === 'true';
  const isUniversityEnabled = process.env.REACT_APP_UNIVERSITY_ENABLED === 'true';
  const isFirstNameEnabled = process.env.REACT_APP_FIRSTNAME_ENABLED === 'true';
  const isLastNameEnabled = process.env.REACT_APP_LASTNAME_ENABLED === 'true';
  const isEmailEnabled = process.env.REACT_APP_EMAIL_ENABLED === 'true';
  const isConfirmEmailEnabled = process.env.REACT_APP_CONFIRM_EMAIL_ENABLED === 'true';
  const isCountryEnabled = process.env.REACT_APP_COUNTRY_ENABLED === 'true';
  const isPhoneEnabled = process.env.REACT_APP_PHONE_ENABLED === 'true';
  const isAddressEnabled = process.env.REACT_APP_ADDRESS_ENABLED === 'true';
  const isMarketingConsentEnabled = process.env.REACT_APP_MARKETING_CONSENT_ENABLED === 'true';
  const isImageConsentEnabled = process.env.REACT_APP_IMAGE_CONSENT_ENABLED === 'true';

  const ukEntry = {
    name: countries.GB.name,
    code: 'GB',
    phone: countries.GB.phone
  };

  let otherCountriesArray = Object.keys(countries).filter(code => code !=='GB').map((code) => ({
    name: countries[code].name,
    code,
    phone: countries[code].phone
  })).sort((a, b) => a.name.localeCompare(b.name));

  const handleCountryChange = (event) => {
    const { value } = event.target;
    const country = value === 'GB' ? ukEntry : otherCountriesArray.find(c => c.code === value);
    setIsUKSelected(value === 'GB');
    setFormData({
      ...formData,
      country: value,
      phoneExtension: country ? country.phone : ''
    });
  };

  const fetchUniversities = async () => {
    try {
      const response = await fetch(`${ process.env.REACT_APP_API_URL }/api/Universities`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    setUniversities(data);
  } catch (error) {
    console.error('Error fetching universities:', error);
  }
};

  useEffect(() => {
    fetchUniversities();
    setResetFunction(() => () => {
      setFormData({
        studentid: '',
        university: { id: '', name: '' },  
        firstname: '',
        lastname: '',
        address: '',
        postcode: '',
        country: '',
        phoneExtension: '',
        phone: '',
        email: '',
        marketingConsent:'',
        imageConsent:'',
        addressline1: '', 
        addressline2: '',
        town: '',
        county: '',
      });
    });
  }, [setResetFunction]);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    if (name === "phone" && value.length > 15) {
      return;
    }

    if (name === 'university') {
      const selectedUniversity = universities.find(univ => univ.id.toString() === value);
      setFormData(prevData => ({
        ...prevData,
        university: selectedUniversity || { id: '', name: '' },
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
    setFormErrors(prevErrors => {
      const newErrors = { ...prevErrors, [name]: '' };
      return newErrors;
    });
  };

  const validateForm = () => {
    let errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.studentid && isStudentIdEnabled) errors.studentid = 'Student ID is required';
    if (!formData.university.id && isUniversityEnabled) errors.university = 'University is required';
    if (!formData.firstname && isFirstNameEnabled) errors.firstname = 'First name is required';
    if (!formData.lastname && isLastNameEnabled) errors.lastname = 'Last name is required';
    if (!formData.phone && isPhoneEnabled) errors.phone = 'Phone is required';
    if (!formData.email && isEmailEnabled) {
      errors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      errors.email = 'Invalid email format';
    }
    if (isEmailEnabled && isConfirmEmailEnabled && formData.email && formData.email !== formData.confirmEmail) {
      errors.confirmEmail = 'Emails do not match';
    }

    if (isAddressEnabled) {
      if (isUKSelected) {
        if (!formData.addressline1) errors.addressline1 = 'Address is required';
        if (!formData.postcode) errors.postcode = 'Postcode is required';
      } else {
        if (!formData.addressline1) errors.addressline1 = 'House Name/No: is required';
        if (!formData.addressline2) errors.addressline2 = 'Street address is required';
        if (!formData.town) errors.town = 'City/Town is required';
        if (!formData.county) errors.county = 'State/Province/County is required';
      }
    }

    return errors;
  };

  const handleAddressLookup = (addressData) => {
    if (Object.keys(addressData).length > 0) {
      const firstAddress = addressData;
      setFormData({
        ...formData,
        addressline1: firstAddress.Address1,
        addressline2: firstAddress.Street, 
        town: firstAddress.Town, 
        county: firstAddress.County,
        postcode: firstAddress.Postcode,
      });
      setFormErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        delete newErrors.addressline1;
        delete newErrors.addressline2;
        delete newErrors.town;
        delete newErrors.county;
        delete newErrors.postcode;
        return newErrors;
      });
      setLookupError(null);
    } else {
      setLookupError('Address not found. Please check the postcode or enter the address manually.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let newErrors = validateForm();
    setFormErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const submitData = {
        studentid: formData.studentid,
        universityId: formData.university?.id,
        universityName: formData.university?.name,
        firstname: formData.firstname,
        lastname: formData.lastname,
        country: formData.country,
        phoneExtension: formData.phoneExtension,
        phone: formData.phone,
        email: formData.email,
        marketingConsent: formData.marketingConsent,
        imageConsent: formData.imageConsent,
        addressline1: formData.addressline1,
        addressline2: formData.addressline2,
        town: formData.town,
        county: formData.county,
        postcode: formData.postcode
      };

      onFormSubmit(submitData);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center mt-3">
      <form className="w-full max-w-lg bg-yellow-500 bg-opacity-60 p-5 rounded-lg" onSubmit={handleSubmit}>
        {isStudentIdEnabled && (
        <div className="mb-4">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="studentid">
            Student ID
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            id="studentid"
            maxLength="50"
            type="text"
            name="studentid"
            value={formData.studentid}
            onChange={handleChange}
            required
          />
          {formErrors.studentid && <p className="text-black text-xs italic">{formErrors.studentid}</p>}
        </div>
        )}

        {isUniversityEnabled && (
          <div className="mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="university"
            >
              University
            </label>
            <select
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              id="university"
              name="university"
              value={formData.university?.id || ''}
              onChange={handleChange}
              required
            >
              <option value="">Select a university</option>
              {universities.map(university => (
                <option key={university.id} value={university.id}>
                  {university.name}
                </option>
              ))}
            </select>
            {formErrors.university && (
              <p className="text-black text-xs italic">{formErrors.university}</p>
            )}
          </div>
        )}

        <div className="flex flex-wrap mb-4">
        {isFirstNameEnabled && (
          <div className="w-full md:w-1/2 md:pr-2 mb-4 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="firstname">
              First Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              id="firstname"
              maxLength="25"
              type="text"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              required
            />
            {formErrors.firstname && <p className="text-black text-xs italic">{formErrors.firstname}</p>}
          </div>
        )}
          {isLastNameEnabled && (
          <div className="w-full md:w-1/2 md:pl-2">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="lastname">
              Last Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              id="lastname"
              maxLength="25"
              type="text"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              required
            />
            {formErrors.lastname && <p className="text-black text-xs italic">{formErrors.lastname}</p>}
          </div>
          )}
        </div>

        {isCountryEnabled && (
        <div className="mb-4">
          <label htmlFor="country" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Country:
          </label>
          <select
            name="country"
            id="country"
            value={formData.country}
            onChange={handleCountryChange}
            className="shadow appearance-none border rounded w-full py-3 px-4 bg-gray-200 text-gray-700"
            required
          >
            <option value="">Select your country</option>
            <optgroup label="Preferred">
              <option value={ukEntry.code}>{ukEntry.name}</option>
            </optgroup>
            <optgroup label="Other">
              {otherCountriesArray.map((country, index) => (
                <option key={index} value={country.code}>{country.name}</option>
              ))}
            </optgroup>
          </select>
          {formErrors.country && <p className="text-black text-xs italic">{formErrors.country}</p>}
        </div>
        )}

      {isPhoneEnabled && ( 
        <div className="mb-4">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="phone">
            Phone
          </label>
          <div className="flex">
            <span className="inline-flex items-center bg-gray-200 text-gray-700 border rounded-l py-3 px-4">
              +{formData.phoneExtension}
            </span>
            <input
              className="flex-1 min-w-0 appearance-none block w-full bg-gray-200 text-gray-700 border rounded-r py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              id="phone"
              type="number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          {formErrors.phone && <p className="text-black text-xs italic">{formErrors.phone}</p>}
        </div>
      )}

      {isEmailEnabled && (
        <div className="mb-4">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="email">
            Email
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 pl-10 px-4 leading-tight focus:outline-none focus:bg-white"
            id="email"
            maxLength="50"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          </div>
          {formErrors.email && <p className="text-black text-xs italic">{formErrors.email}</p>}
        </div>
      )}

      {isConfirmEmailEnabled && (
        <div className="mb-6">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="confirmEmail">
            Confirm Email
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
             className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 pl-10 px-4 leading-tight focus:outline-none focus:bg-white"
            id="confirmEmail"
            maxLength="50"
            type="email"
            name="confirmEmail"
            value={formData.confirmEmail}
            onChange={handleChange}
            required
          />
          </div>
          {formErrors.confirmEmail && <p className="text-black text-xs italic">{formErrors.confirmEmail}</p>}
        </div>
      )}

    {isAddressEnabled && (
      <>   
        {formData.country && ( 
          <> 
            {isUKSelected ? (
              <> 
              <AddressLookupDrop setParentAddress={handleAddressLookup} lookupError={lookupError} setLookupError={setLookupError} />
              {lookupError && <p className="text-black text-xs italic">{lookupError}</p>} 
            </>
             ) : ( 
              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-3 sm:grid-cols-6">  
              <div className="col-span-full">
                <label
                  htmlFor="addressline1"
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                >
                  House Name/No:
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="addressline1"
                    id="addressline1"
                    maxLength="25"
                    autoComplete="addressline1"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    onChange={handleChange}
                    value={formData.addressline1}
                    required
                  />{formErrors.addressline1 && <p className="text-black text-xs italic">{formErrors.addressline1}</p>}
                </div>
                </div> 
               <div className="col-span-full">
                <label htmlFor="addressline2" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Street address
                </label>
                <div className="mt-2">
                <input 
                    type="text"
                    name="addressline2"
                    id="addressline2"
                    maxLength="25"
                    autoComplete="addressline2"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    onChange={handleChange}
                    value={formData.addressline2}
                    required
                  />{formErrors.addressline2 && <p className="text-black text-xs italic">{formErrors.addressline2}</p>}
                </div>
              </div> 

              <div className="sm:col-span-2 sm:col-start-1">
                 <label htmlFor="city" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                   City
                 </label>
                 <div className="mt-2">
                   <input
                    type="text"
                    name="town"
                    id="town"
                    maxLength="16"
                    autoComplete="town"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    onChange={handleChange}
                    value={formData.town}
                    required
                  />{formErrors.town && <p className="text-black text-xs italic">{formErrors.town}</p>}
                </div>
              </div> 

               <div className="sm:col-span-2">
                 <label htmlFor="region" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                   State / Province
                 </label>
                 <div className="mt-2">
                   <input
                     type="text"
                     name="county"
                      id="county"
                      maxLength="25"
                      autoComplete="county"
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      onChange={handleChange}
                      value={formData.county}
                      required
                  />{formErrors.county && <p className="text-black text-xs italic">{formErrors.county}</p>}
                </div>
              </div> 

               <div className="sm:col-span-2">
                 <label htmlFor="postal-code" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                   ZIP / Postal code
                 </label>
                 <div className="mt-2">
                   <input
                     type="text"
                     name="postcode"
                     id="postcode"
                     maxLength="10"
                     autoComplete="postcode"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                     onChange={handleChange}
                     value={formData.postcode}
                    required
                   />{formErrors.postcode && <p className="text-black text-xs italic">{formErrors.postcode}</p>}
                 </div> 
               </div>
               </div> 
             )}
          </>
        )}
        </> 
    )}    

      {isMarketingConsentEnabled && (
        <div className="mb-2">
          <label>
            <input
              type="checkbox"
              className="mr-2 rounded"
              name="marketingConsent"
              checked={formData.marketingConsent}
              onChange={handleChange}
            />
            <span className="text-black text-xs select-none">
            I agree to receive reminders & updates about my order.
            </span>
          </label>
        </div>
      )}

    {isImageConsentEnabled && (
      <div className="mb-2">
        <label>
          <input
            type="checkbox"
            className="mr-2 rounded"
            name="imageConsent"
            checked={formData.imageConsent}
            onChange={handleChange}
          />
          <span className="text-black text-xs select-none">
          I agree to the use of my images for promotion and testing.
          </span>
        </label>
      </div>
    )}

        <div className="flex justify-center">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default FormComponent;
