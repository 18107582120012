import React from 'react';

function TopBar() {
  return (
    <div className=" text-white p-4 shadow-md">
      <h1 className="text-left text-2xl font-bold">
        Tempest Graduations
      </h1>
    </div>
  );
}

export default TopBar;
